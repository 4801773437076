import { Box, SxProp } from 'theme-ui';
import React from 'react';
import ContentFeatureHero from './ContentFeatureHero';
import ContentSection from '~/components/Content/Section';

type ContentPageProps = {
  page: Queries.ContentPageFragmentFragment;
  forwardSx?: SxProp;
  pageContext: Record<string, unknown>;
};

const ContentPage = React.forwardRef<HTMLElement, ContentPageProps>(({ page, forwardSx, pageContext }, ref) => {
  const { hero, mobileHero, backgroundColor, transparentNav } = page;
  return (
    <>
      <Box sx={{ backgroundColor: backgroundColor ?? 'white', ...forwardSx }}>
        {(hero || mobileHero) && (
          <ContentFeatureHero
            //@ts-ignore
            hero={hero}
            mobileHero={mobileHero}
            forwardSx={forwardSx}
            ref={ref}
            transparentNav={transparentNav}
          />
        )}
        {page.sections &&
          page.sections.map((section) => {
            const collectionList = section?.media?.[0]?.__typename === 'ContentfulCollection';
            return (
              <ContentSection
                key={section?.id}
                // @ts-ignore
                collectionList={collectionList}
                section={section}
                page={page}
                pageContext={pageContext}
              />
            );
          })}
      </Box>
    </>
  );
});

export default React.memo(ContentPage);
